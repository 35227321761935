let ca, es, en;
let languageTranslations = {ca, es, en};
export const setLoadedData = (data) => {
	ca = data.ca;
	es = data.es;
	en = data.en;
	languageTranslations = {ca, es, en};
};

const index = (obj, target) => target.split('.').reduce((o, i) => o[i], obj);

export const availableLanguages = () => Object.keys(languageTranslations);

export const checkLanguage = (lang) => availableLanguages().includes(lang);

export const defaultLang = 'es';

export const translate = (target, lang = defaultLang) => {
	try {
		if (!target) throw new Error('No target specified');
		if (!checkLanguage(lang)) lang = defaultLang;
		const translation = index(languageTranslations[lang], target);
		if (!translation) throw new Error('No value found with target ' + target);
		if (typeof translation != 'string')
			throw new Error(`Found ${typeof translation} in ${target}`);
		return translation;
	} catch (error) {
		console.error(error);
		return target;
	}
};

export const getPathsFor = (path, lang, withLang = true) => {
	withLang = withLang && lang !== defaultLang;
	if (path === 'main') return withLang ? `${lang}/` : '';
	let result = '';
	if (!!lang) result = `${translate(`routes.${path}`, lang).toLowerCase()}`;
	else
		result = availableLanguages().map(
			(l) => `${translate(`routes.${path}`, l).toLowerCase()}`
		);
	if (!!lang) return withLang ? `${lang}/${result}` : result;
	else return result;
};
