import React, {useContext, useEffect, useState} from 'react';
import {Box, Container, Typography, useTheme} from '@mui/material';
import UserContext from '../../contexts/UserContext';
import Section from '../ui/Section';
import Literal from '../utils/Literal';
import {translate} from '../utils/translations';
import parse from 'html-react-parser';

function LegalPolicy() {
	const {palette} = useTheme();
	const {lang} = useContext(UserContext);

	const [hasScrolled, setHasScrolled] = useState(false);
	useEffect(() => {
		if (hasScrolled) return;
		window.scroll({top: 0, left: 0, behavior: 'smooth'});
		setHasScrolled(true);
	}, [hasScrolled]);

	return (
		<>
			<h1 style={{display: 'none'}}>{translate('legalPolicy.title', lang)}</h1>
			<Box
				padding={3}
				textAlign={'center'}
				sx={{
					color: palette.primary.contrastText,
					background: `linear-gradient(107.56deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%)`,
				}}>
				<Typography component={'p'} variant={'h6'} color='inherit'>
					<Literal target='legalPolicy.subtitle' lang={lang} />
				</Typography>
			</Box>
			<Section>
				<Container maxWidth='xl' sx={{'& a': {color: palette.primary.main}}}>
					<Typography component={'div'} variant={'p'} color='inherit'>
						{parse(translate('legalPolicy.body', lang))}
					</Typography>
				</Container>
			</Section>
		</>
	);
}

export default LegalPolicy;
