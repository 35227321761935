import React, {useContext} from 'react';
import {
	Container,
	Grid,
	Stack,
	Link as MuiLink,
	Typography,
	useTheme,
} from '@mui/material';
import Section from '../ui/Section';
import UserContext from '../../contexts/UserContext';
import {Link} from 'react-router-dom';
import {getPathsFor} from '../utils/translations';
import Picture from '../ui/Picture';
import {useDataContext} from '../../contexts/DataContext';

function Footer() {
	const {lang} = useContext(UserContext);
	const {getData} = useDataContext();
	const {palette} = useTheme();

	const [{products}, {social, footer}] = getData('products', 'menu');

	return (
		<>
			<Section
				sx={{
					color: palette.primary.contrastText,
					background: `linear-gradient(107.56deg, ${palette.primary.dark} 0%, ${palette.primary.light} 100%)`,
				}}>
				<Container maxWidth='lg'>
					<Grid
						container
						direction={{sx: 'column', md: 'row'}}
						mb={4}
						spacing={4}
						justifyContent='space-between'>
						<Grid item>
							<Stack direction={'column'} spacing={4}>
								<Picture
									src={'/public/static/imgs/Logo Text Inverted - text.png'}
									alt='Eturistic Logo'
									style={{maxWidth: '250px'}}
									loading='lazy'
								/>
								<Stack direction={'row'} spacing={4}>
									{social.map((s, i) => (
										<MuiLink
											key={i}
											href={s.href}
											target='_blank'
											color='inherit'
											underline='none'
											rel='nofollow'>
											{s.name[lang]}
										</MuiLink>
									))}
								</Stack>
								<Stack direction={'row'} spacing={4}>
									{footer.map((f, i) => (
										<Link
											key={i}
											to={`/${getPathsFor(f.ref, lang)}`}
											style={{textDecoration: 'none', color: 'inherit'}}>
											{f.name[lang]}
										</Link>
									))}
								</Stack>
							</Stack>
						</Grid>
						<Grid item>
							<Grid container direction={{sx: 'row', md: 'column'}} spacing={4}>
								{products.map((p, i) => {
									const basePath = getPathsFor('products', lang).replace('#', '');
									const productPath = p.name[lang].toLowerCase().replaceAll(' ', '-');
									return (
										<Grid item key={i}>
											<Link
												to={`/${basePath}/${productPath}`}
												style={{textDecoration: 'none', color: 'inherit'}}>
												{p.name[lang]}
											</Link>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
					</Grid>
					<Typography variant='body'>Copyright © 2019 Eturistic.</Typography>
				</Container>
			</Section>
			<Section>
				<Container maxWidth='xl'>
					<Link to={`/${getPathsFor('endesa', lang)}`}>
						<Picture
							alt={'Endesa Ayuda'}
							src={'/public/static/imgs/endesa.png'}
							style={{display: 'block', maxWidth: '100%', margin: 'auto'}}
							parentStyle={{display: 'block'}}
							loading='lazy'
						/>
					</Link>
				</Container>
			</Section>
		</>
	);
}

export default Footer;
