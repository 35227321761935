import React, {useContext, useEffect, useRef, useState} from 'react';
import UserContext from '../../contexts/UserContext';
import {Box, Link, Typography} from '@mui/material';
import Section from '../ui/Section';
import Carousel, {carouselClasses} from 'mui-carousel';
import Literal from '../utils/Literal';
import {useLocation} from 'react-router-dom';
import {getPathsFor} from '../utils/translations';
import Picture from '../ui/Picture';
import {useDataContext} from '../../contexts/DataContext';

function Clients() {
	const path = useLocation();
	const sectionRef = useRef();

	const {lang} = useContext(UserContext);
	const {getData} = useDataContext();
	const [{clients}] = getData('clients');
	const speedMs = 3500;
	const [clientsFit, setClientsFit] = useState(-1);

	useEffect(() => {
		if (typeof document !== 'undefined' && clientsFit === -1)
			setClientsFit(Math.round(document.body.scrollWidth / 250));
	}, [clientsFit]);

	useEffect(() => {
		if (!sectionRef?.current) return;
		if (!getPathsFor('clients').includes(path.hash)) return;
		const section = sectionRef.current;
		window.scrollTo({top: section.offsetTop, left: 0, behavior: 'smooth'});
	}, [path, sectionRef]);

	return (
		<Box ref={sectionRef}>
			<Section position={'relative'} sx={{overflow: 'hidden'}}>
				<Typography mb={5} component={'h3'} variant={'h3'} textAlign={'center'}>
					<Literal target='ourClients' lang={lang} />
				</Typography>
				<Box
					sx={{
						position: 'absolute',
						left: 0,
						width: '35px',
						height: '100%',
						background: 'linear-gradient(90deg, #fff 0%, transparent 100%)',
						zIndex: 10,
					}}></Box>
				<Box
					sx={{
						position: 'absolute',
						right: 0,
						width: '35px',
						height: '100%',
						background: 'linear-gradient(-90deg, #fff 0%, transparent 100%)',
						zIndex: 10,
					}}></Box>

				<Carousel
					speed={speedMs}
					dots={false}
					arrows={false}
					showSlides={clientsFit > clients.length ? clients.length : clientsFit}
					alignItems={'center'}
					autoPlay
					infinity
					centerMode
					sx={{
						[`& .${carouselClasses.item}`]: {
							verticalAlign: 'middle',
						},
					}}>
					{clients.map((c, i) => (
						<Link
							key={i}
							m={'auto'}
							rel={'nofollow'}
							display={'block'}
							href={c.link || ''}
							target={'_blank'}
							sx={{width: '250px', padding: {xs: 5, md: 4, xl: 0}}}>
							<Picture
								src={c.img}
								alt={c.name}
								loading='lazy'
								style={{
									width: '100%',
									maxWidth: '100%',
									filter: `grayscale(0.3) brightness(${c.brightness || 1})`,
								}}
							/>
						</Link>
					))}
				</Carousel>
			</Section>
		</Box>
	);
}

export default Clients;
