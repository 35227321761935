import React from 'react';
import {Box, CircularProgress, useTheme} from '@mui/material';

function Loading() {
	const {palette} = useTheme();

	return (
		<Box
			position={'fixed'}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100vw',
				height: '100vh',
				color: 'white',
				background: `linear-gradient(107.56deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%)`,
				zIndex: 5000,
			}}>
			<CircularProgress color='inherit' />
		</Box>
	);
}

export default Loading;
