import React, {createContext, Suspense, useContext, useEffect, useState} from 'react';
import Loading from '../components/ui/Loading';
import {loadJSON} from '../components/utils/jsonLoader';
import {setLoadedData} from '../components/utils/translations';
import UserContext from './UserContext';

let data = {};

export const setData = (newData) => {
	data = newData;
	setLoadedData(newData);
};

const index = (obj, path) => path.split('.').reduce((o, i) => o[i], obj);
const setter = (obj, path, value) => {
	if (typeof path == 'string') return setter(obj, path.split('.'), value);
	else if (path.length == 1 && value !== undefined) return (obj[path[0]] = value);
	else if (path.length == 0) return obj;
	else return setter(obj[path[0]], path.slice(1), value);
};

const DataContextInstance = createContext({
	getData: async (...paths) => ({}),
});

export const DataContext = ({dataLoaded, children}) => {
	if (dataLoaded) data = dataLoaded;

	const {lang} = useContext(UserContext);
	const [prevLang, setPrevLang] = useState();

	const loadData = (path) => {
		if (Object.keys(data).length > 0) {
			const foundData = index(data, path || lang);
			if (foundData?.then) throw foundData;
			if (foundData) return data;
		}
		const filePath = `/data/${path || `translations/${lang}`}.json`;
		if (!path) path = lang;
		const json = loadJSON(filePath).then((newData) => (data[path] = newData));
		data[path] = json;
		if (data[path]?.then) throw data[path];
	};

	const getData = (...paths) => paths.map((p) => loadData(p)[p || lang]);

	useEffect(() => {
		if (lang === prevLang && Object.keys(data).length > 0) return;
		if (Object.keys(data).length > 0) return;
		loadData();
		setPrevLang(lang);
	}, [lang, prevLang, setPrevLang, data]);

	return (
		<DataContextInstance.Provider value={{getData}}>
			<Suspense fallback={<Loading />}>{children}</Suspense>
		</DataContextInstance.Provider>
	);
};

export const useDataContext = () => {
	const context = useContext(DataContextInstance);
	if (!context) throw new Error('Context must be used within a Provider');
	return context;
};
