import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, Container, Grid, styled, Typography, useTheme} from '@mui/material';
import UserContext from '../../contexts/UserContext';
import Section from '../ui/Section';
import {NavLink} from 'react-router-dom';
import {getPathsFor} from '../utils/translations';
import parse from 'html-react-parser';
import Picture from '../ui/Picture';
import {useDataContext} from '../../contexts/DataContext';

function ProductPage({product}) {
	const {lang} = useContext(UserContext);
	const {getData} = useDataContext();
	const [{products}] = getData('products');

	const {palette, shape, transitions} = useTheme();

	const StyledNavLink = styled(NavLink)({
		position: 'relative',
		textDecoration: 'none',
		color: 'inherit',
		'&::after': {
			content: '""',
			position: 'absolute',
			left: '50%',
			bottom: -10,
			width: 0,
			height: 3,
			backgroundColor: 'white',
			borderRadius: shape.borderRadius,
			zIndex: 10,
			transition: `all ${transitions.duration.short}ms ${transitions.easing.easeInOut}`,
			transform: 'translateX(-50%)',
		},
		'&.active::after': {
			width: '50%',
		},
	});

	const [hasScrolled, setHasScrolled] = useState(false);
	useEffect(() => {
		if (hasScrolled) return;
		window.scroll({top: 0, left: 0, behavior: 'smooth'});
		setHasScrolled(true);
	}, [hasScrolled]);

	return (
		<>
			<h1 style={{display: 'none'}}>{product.name[lang]}</h1>
			<Box
				padding={3}
				mb={4}
				sx={{
					background: `linear-gradient(107.56deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%)`,
				}}>
				<Container maxWidth='lg'>
					<Grid
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
						spacing={4}
						sx={{color: palette.primary.contrastText}}>
						{products.map((p, i) => {
							const basePath = getPathsFor('products', lang).replace('#', '');
							const productPath = p.name[lang].toLowerCase().replaceAll(' ', '-');
							return (
								<Grid key={i} item>
									<StyledNavLink to={`/${basePath}/${productPath}`}>
										<Button variant='text' color='inherit'>
											{p.name[lang]}
										</Button>
									</StyledNavLink>
								</Grid>
							);
						})}
					</Grid>
				</Container>
			</Box>
			<Container maxWidth='xl'>
				<Section>
					<Typography component={'h2'} variant={'title'} mb={3}>
						{product.name[lang]}
					</Typography>
					<Typography component={'p'} variant={'body'} mb={3}>
						{parse(product.description[lang])}
					</Typography>
					{product.sections?.map((section, i) => {
						const hasTwo = !!section.img && !!section.body;
						const rows = hasTwo ? 6 : 12;
						return (
							<Box key={i}>
								{!!section.title && !section.body ? (
									<Typography component={'h3'} variant={'h4'}>
										{section.title[lang]}
									</Typography>
								) : (
									<></>
								)}
								<Grid
									container
									paddingTop={10}
									spacing={5}
									direction={{
										xs: 'column',
										md: !!section.reversed ? 'row-reverse' : 'row',
									}}>
									{!!section.body ? (
										<Grid item xs={rows}>
											<Typography component={'h3'} variant={'h4'} mb={3}>
												{section.title[lang]}
											</Typography>
											<Typography component={'p'} variant={'body'}>
												{parse(section.body[lang])}
											</Typography>
										</Grid>
									) : (
										<></>
									)}
									{!!section.img ? (
										<Grid item xs={rows}>
											<Picture
												alt={section.img.alt}
												src={section.img.src}
												style={{borderRadius: shape.borderRadius, ...section.img.style}}
											/>
										</Grid>
									) : (
										<></>
									)}
								</Grid>
							</Box>
						);
					})}
				</Section>
			</Container>
		</>
	);
}

export default ProductPage;
