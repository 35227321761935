import React, {useContext, useState} from 'react';
import UserContext from '../../contexts/UserContext';
import {defaultLang, translate} from '../utils/translations';
import {FormControl, InputLabel, MenuItem, Select, Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Literal from '../utils/Literal';
import {useDataContext} from '../../contexts/DataContext';

function ContactForm({variant}) {
	const {lang} = useContext(UserContext);

	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const required = ['email', 'message'];
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const body = {lang};
		new FormData(e.target).forEach((v, k) => {
			const translation = translate(`form.${k}`, defaultLang);
			body[required.includes(k) ? k : `extra_${k}_label`] = translation;
			body[required.includes(k) ? k : `extra_${k}_value`] = v;
		});
		body['extra_contactType_label'] = 'Tipo de contacto';
		body['extra_contactType_value'] = variant || 'contact';
		if (!body.message) body.message = 'EMPTY MESSAGE';
		fetch('/contact', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(body),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) return e.target.reset();
				setError(response.input || '');
			})
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
	};

	return (
		<form onSubmit={handleSubmit} onChange={() => setError('')} style={{zIndex: 1000}}>
			{variant === 'demo' ? (
				<DemoFields lang={lang} loading={isLoading} error={error} />
			) : (
				<ContactFields lang={lang} loading={isLoading} error={error} />
			)}
		</form>
	);
}

function ContactFields({lang, loading, error}) {
	return (
		<FormControl fullWidth sx={{gap: 2}} error={!!error}>
			<Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
				<TextField
					name='firstName'
					label={translate('form.firstName', lang)}
					required
					sx={{flexGrow: 1}}
				/>
				<TextField
					name='lastName'
					label={translate('form.lastName', lang)}
					required
					sx={{flexGrow: 1}}
				/>
			</Stack>
			<Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
				<TextField
					name='email'
					type='email'
					error={error === 'email'}
					label={translate('form.email', lang)}
					required
					sx={{flexGrow: 1}}
				/>
				<TextField
					name='phone'
					label={translate('form.phone', lang)}
					sx={{flexGrow: 1}}
				/>
			</Stack>
			<TextField name='message' label={translate('form.note', lang)} multiline rows={8} />
			<LoadingButton
				size='large'
				variant='contained'
				color='secondary'
				loading={loading}
				type='submit'>
				<Literal target='form.send' lang={lang} />
			</LoadingButton>
		</FormControl>
	);
}

function DemoFields({lang, loading, error}) {
	const {getData} = useDataContext();
	const [{properties}] = getData('properties');
	const propertyTypes = properties.map((p) => ({
		value: p.value,
		display: p.name[lang],
	}));

	const [propertyType, setPropertyType] = useState('');

	const handleChange = (event) => {
		setPropertyType(event.target.value);
	};

	return (
		<FormControl fullWidth sx={{gap: 2}} error={!!error}>
			<TextField
				name='propertyName'
				label={translate('form.propertyName', lang)}
				required
			/>
			<FormControl>
				<InputLabel id='select-type-label'>
					<Literal target='form.propertyType' lang={lang} /> *
				</InputLabel>
				<Select
					labelId='select-type-label'
					label={translate('form.propertyType', lang)}
					name='propertyType'
					value={propertyType}
					onChange={handleChange}
					required>
					{propertyTypes.map(({value, display}, i) => (
						<MenuItem key={i} value={value}>
							{display}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<TextField name='fullName' label={translate('form.fullName', lang)} required />
			<TextField
				name='email'
				type='email'
				error={error === 'email'}
				label={translate('form.email', lang)}
				required
			/>
			<TextField name='phone' label={translate('form.phone', lang)} />
			<TextField name='message' label={translate('form.note', lang)} multiline rows={2} />
			<LoadingButton
				size='large'
				variant='contained'
				color='secondary'
				loading={loading}
				type='submit'>
				<Literal target='form.requestDemo' lang={lang} />
			</LoadingButton>
		</FormControl>
	);
}

export default ContactForm;
