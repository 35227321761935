import React, {useContext} from 'react';
import {ClickAwayListener, Container, Grid, Typography, useTheme} from '@mui/material';
import Section from '../ui/Section';
import {Link} from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import {getPathsFor, translate} from '../utils/translations';

function Error({statusCode, message}) {
	const {lang} = useContext(UserContext);
	const {palette} = useTheme();

	const status = statusCode && statusCode !== 200 ? statusCode : 404;

	return (
		<ClickAwayListener
			onClickAway={() => setTimeout(() => window.location.reload(), 500)}>
			<Container maxWidth='xl'>
				<Section sx={{paddingTop: 5, paddingBottom: 15}}>
					<Grid container textAlign={'center'}>
						<Grid item xs={12}>
							<Typography
								component={'h1'}
								variant='h1'
								fontSize={350}
								fontWeight={900}
								sx={{opacity: 0.2}}>
								{status}
							</Typography>
						</Grid>
						<Grid item xs={12} mt={-20}>
							<Typography component={'h2'} variant='h1' fontSize={150} fontWeight={600}>
								{translate(`${status}.title`, lang)}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography component={'p'} variant='body1'>
								{translate(`${status}.message`, lang)}
								<Link
									to={`/${getPathsFor(translate(`${status}.redirect.url`, lang), lang)}`}
									style={{color: palette.primary.main}}>
									{translate(`${status}.redirect.title`, lang)}
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Section>
			</Container>
		</ClickAwayListener>
	);
}

export default Error;
