import React, {useMemo, useState} from 'react';
import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import {checkLanguage, defaultLang} from './components/utils/translations';
import UserContext from './contexts/UserContext';
import {DataContext, useDataContext} from './contexts/DataContext';
import Routes from './components/Routes';

function App({data, pathLang, statusCode, cookiesAccepted}) {
	const customTheme = () => {
		const primary = {
			light: '#019ADE ',
			main: '#017BB8',
			dark: '#005A8F',
			contrastText: '#FFFFFF',
		};

		const secondary = {
			main: '#08C49D',
			contrastText: '#FFFFFF',
		};

		return {
			palette: {
				primary: primary,
				secondary: secondary,
			},
			components: {
				MuiTypography: {
					variants: [
						{
							props: {variant: 'title'},
							style: {
								position: 'relative',
								fontSize: 48,
								fontWeight: 500,
								'&::after': {
									display: 'block',
									content: '""',
									height: 4,
									width: '100px',
									backgroundColor: primary.main,
									borderRadius: 4,
								},
							},
						},
					],
				},
			},
		};
	};
	const theme = createTheme(customTheme());

	const getStoredLang = () => {
		if (typeof window !== 'undefined') {
			if (!pathLang) pathLang = window.location.pathname.split('/')[1];
			return (
				pathLang ||
				localStorage.getItem('lang') ||
				navigator.language ||
				navigator.userLanguage
			);
		}
		return pathLang || defaultLang;
	};

	const userLanguage = useMemo(getStoredLang, [pathLang, defaultLang]);
	const [lang, setLang] = useState(
		checkLanguage(userLanguage) ? userLanguage : defaultLang
	);

	const changeLanguage = (lang) => {
		if (!checkLanguage(lang)) return;
		localStorage.setItem('lang', lang);
		setLang(lang);
	};

	return (
		<ThemeProvider theme={theme}>
			<UserContext.Provider value={{lang, setLang: changeLanguage, cookiesAccepted}}>
				<CssBaseline />
				<DataContext dataLoaded={data}>
					<AppBase statusCode={statusCode} />
				</DataContext>
			</UserContext.Provider>
		</ThemeProvider>
	);
}

const AppBase = ({statusCode}) => {
	const {getData} = useDataContext();
	const loadedData = getData('kitdigital', 'products');

	return <Routes data={loadedData} statusCode={statusCode} />;
};

export default App;
