import React, {useContext, useEffect, useState} from 'react';
import {Container, Typography, Link as MuiLink, useTheme} from '@mui/material';
import Section from '../../ui/Section';
import parse from 'html-react-parser';
import KitDigitalSegments from './KitDigitalSegments';
import {Link} from 'react-router-dom';
import {getPathsFor} from '../../utils/translations';
import UserContext from '../../../contexts/UserContext';
import Literal from '../../utils/Literal';

function KitDigitalDetail({pack: {title, category, body}}) {
	const {lang} = useContext(UserContext);
	const {palette} = useTheme();

	const [, setHasScrolled] = useState(false);
	useEffect(() => {
		window.scroll({top: 0, left: 0, behavior: 'smooth'});
		setHasScrolled(true);
	}, [title]);

	return (
		<Container maxWidth={'xl'} sx={{'& a': {color: palette.primary.main}}}>
			<h1 style={{display: 'none'}}>
				{'Kit Digital - '}
				{title}
			</h1>
			<Section>
				<Typography gutterBottom variant='h3' mb={1}>
					{title}
				</Typography>
				<Typography gutterBottom variant='subtitle1' color={'GrayText'} mb={5}>
					{category}
				</Typography>
				<Typography gutterBottom variant='body1' component={'div'}>
					{parse(body || '')}
				</Typography>
			</Section>
			<KitDigitalSegments />
			<Typography variant='body1' mb={1} color='primary'>
				<MuiLink href='https://www.acelerapyme.gob.es/kit-digital' target='_blank'>
					Acelerapyme Kit Digital
				</MuiLink>
			</Typography>
			<Typography variant='body1'>
				Haz tu{' '}
				<MuiLink
					href='https://www.acelerapyme.gob.es/quieres-conocer-el-grado-de-digitalizacion-de-tu-pyme'
					target='_blank'>
					autodiagnóstico
				</MuiLink>{' '}
				o si lo prefieres solicita que uno de nuestros agentes lo tramite por ti.
				<Link to={`/${getPathsFor('contact', lang)}`} target='_blank'>
					<Literal target='contact' lang={lang} />
				</Link>
			</Typography>
		</Container>
	);
}

export default KitDigitalDetail;
