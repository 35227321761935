import React, {useContext, useState} from 'react';
import UserContext from '../../contexts/UserContext';
import {Box, Container, Grid, TextField, Typography, useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {NotificationsActiveRounded, NotificationsRounded} from '@mui/icons-material';
import Section from '../ui/Section';
import Literal from '../utils/Literal';

function Newsletter() {
	const {lang} = useContext(UserContext);
	const {palette} = useTheme();

	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData(e.target);
		fetch(`/subscribe/${formData.get('email')}`)
			.then((response) => response.json())
			.then((response) => {
				if (response.success) return e.target.reset();
				setError(response.input || '');
			})
			.finally(() => setLoading(false));
	};

	return (
		<Section
			position={'relative'}
			mt={10}
			textAlign={'center'}
			sx={{
				color: 'white',
			}}>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					width: '100%',
					height: '100%',
					background: `url(/public/static/imgs/nwl-bg.webp) no-repeat center`,
					backgroundSize: 'cover',
					opacity: 0.6,
					zIndex: -10,
				}}></Box>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'black',
					zIndex: -20,
				}}></Box>
			<Box sx={{zIndex: 100}}>
				<Typography component={'h3'} variant={'h4'} textTransform={'uppercase'}>
					<Literal target='newsletter.title' lang={lang} />
				</Typography>
				<Typography
					mt={1}
					mb={5}
					component={'h4'}
					variant={'body'}
					textTransform={'uppercase'}
					fontWeight={400}>
					<Literal target='newsletter.subtitle' lang={lang} />
				</Typography>
				<Container maxWidth={'md'}>
					<form onSubmit={handleSubmit} onChange={() => setError('')}>
						<Grid container justifyContent={'center'}>
							<Grid item xs={8} md={8}>
								<TextField
									fullWidth
									id='email'
									name='email'
									error={error === 'email'}
									label='Email'
									variant='filled'
									color='secondary'
									sx={{backgroundColor: palette.background.paper}}
								/>
							</Grid>
							<Grid item xs={3} md={4}>
								<LoadingButton
									type='submit'
									loading={isLoading}
									variant='contained'
									size='large'
									color='secondary'
									fullWidth
									loadingIndicator={<NotificationsActiveRounded />}
									endIcon={<NotificationsRounded />}
									sx={{
										alignItems: 'center',
										justifyContent: 'center',
										height: '100%',
										borderTopLeftRadius: 0,
										borderBottomLeftRadius: 0,
										backgroundColor: palette.secondary.main,
										'& > span': {
											marginTop: '-2px',
											marginLeft: {xs: '0', md: '5px'},
										},
										'&.MuiLoadingButton-loading': {
											backgroundColor: palette.secondary.main,
											'& > .MuiLoadingButton-loadingIndicator': {
												color: palette.secondary.contrastText,
											},
										},
									}}>
									<Typography
										component={'p'}
										variant='body'
										sx={{display: {xs: 'none', md: 'initial'}}}>
										<Literal target='newsletter.subscribe' lang={lang} />
									</Typography>
								</LoadingButton>
							</Grid>
						</Grid>
					</form>
				</Container>
			</Box>
		</Section>
	);
}

export default Newsletter;
