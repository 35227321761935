import React, {useContext} from 'react';
import UserContext from '../../contexts/UserContext';
import {Container, Typography} from '@mui/material';
import Section from '../ui/Section';
import Literal from '../utils/Literal';

function CTA() {
	const {lang} = useContext(UserContext);

	return (
		<Section>
			<Container maxWidth='xl' sx={{gap: 3}}>
				<Typography component={'h3'} variant={'h3'} textAlign={'center'}>
					<Literal target='slogan' lang={lang} />
				</Typography>
			</Container>
		</Section>
	);
}

export default CTA;
