import React, {useContext, useEffect, useRef, useState} from 'react';
import UserContext from '../../../contexts/UserContext';
import {useLocation} from 'react-router-dom';
import {Box, Button, Container, Grid, useTheme} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import Product from './Product';
import {ArrowForwardRounded, ArrowBackRounded} from '@mui/icons-material';
import Section from '../../ui/Section';
import {getPathsFor} from '../../utils/translations';
import {useDataContext} from '../../../contexts/DataContext';

function Products() {
	const path = useLocation();
	const sectionRef = useRef();

	const {lang} = useContext(UserContext);
	const {getData} = useDataContext();
	const [{products}] = getData('products');

	const {palette, shape, transitions} = useTheme();
	const maxIndex = products.length;
	const [index, setIndex] = useState(0);

	const handleNext = () => {
		setIndex((prev) => (prev + 1 > maxIndex - 1 ? 0 : prev + 1));
	};

	const handleBack = () => {
		setIndex((prev) => (prev - 1 < 0 ? maxIndex - 1 : prev - 1));
	};

	const handleChange = (index) => {
		setIndex(index);
	};

	useEffect(() => {
		if (!sectionRef?.current) return;
		if (!getPathsFor('products').includes(path.hash)) return;
		const section = sectionRef.current;
		window.scrollTo({top: section.offsetTop, left: 0, behavior: 'smooth'});
	}, [path, sectionRef]);

	return (
		<Section>
			<Container
				ref={sectionRef}
				maxWidth='lg'
				sx={{display: 'flex', paddingTop: 10, paddingBottom: 10, alignItems: 'stretch'}}>
				<Button
					variant='text'
					color='inherit'
					onClick={handleBack}
					sx={{display: {xs: 'none', md: 'initial'}}}>
					<ArrowBackRounded />
				</Button>
				<SwipeableViews
					axis='x'
					index={index}
					onChangeIndex={handleChange}
					style={{marginLeft: 15, marginRight: 15}}>
					{products.map((p, i) => (
						<Product key={i} {...p} />
					))}
				</SwipeableViews>
				<Button
					variant='text'
					color='inherit'
					onClick={handleNext}
					sx={{display: {xs: 'none', md: 'initial'}}}>
					<ArrowForwardRounded />
				</Button>
			</Container>
			<Box
				paddingY={2}
				sx={{
					background: `linear-gradient(107.56deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%)`,
				}}>
				<Container maxWidth='lg'>
					<Grid
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
						spacing={4}
						sx={{color: palette.primary.contrastText}}>
						{products.map((p, i) => (
							<Grid key={i} item>
								<Button
									variant='text'
									color='inherit'
									onClick={() => handleChange(i)}
									sx={{
										fontWeight: 400,
										'&::after': {
											content: '""',
											position: 'absolute',
											bottom: 0,
											width: index === i ? '50%' : 0,
											height: 3,
											backgroundColor: 'white',
											borderRadius: shape.borderRadius,
											zIndex: 10,
											transition: `${transitions.duration.short}ms ${transitions.easing.easeInOut}`,
										},
									}}>
									{p.name[lang]}
								</Button>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>
		</Section>
	);
}

export default Products;
