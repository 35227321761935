import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../../contexts/UserContext';
import {
	AppBar,
	Box,
	Container,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	Button,
	Select,
} from '@mui/material';
import {MenuRounded} from '@mui/icons-material';
import {availableLanguages, defaultLang, getPathsFor} from '../utils/translations';
import {NavLink, useNavigate} from 'react-router-dom';
import Picture from '../ui/Picture';
import {useDataContext} from '../../contexts/DataContext';

function NavBar() {
	const {lang, setLang} = useContext(UserContext);
	const navigate = useNavigate();
	const {getData} = useDataContext();

	const [{pages}] = getData('menu');

	const [anchorElNav, setAnchorElNav] = useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<AppBar
			position='relative'
			color='inherit'
			component={'nav'}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100px',
				boxShadow: 0,
				zIndex: 100,
			}}>
			<Container maxWidth='xl'>
				<Toolbar disableGutters>
					<Box noWrap sx={{mr: 2, display: 'flex'}}>
						<NavLink to={`/${getPathsFor('main', lang)}`}>
							<Picture
								src={`/public/static/imgs/Logo%20Text%20-%20text.png`}
								alt={'Eturistic'}
								style={{height: '70px', maxWidth: '100%', objectFit: 'contain'}}
							/>
						</NavLink>
					</Box>
					<Box
						sx={{
							flexDirection: {xs: 'row-reverse', md: 'row'},
							flexGrow: 1,
							display: 'flex',
							justifyContent: {xs: 'end', md: 'right'},
							alignItems: 'center',
							gap: '25px',
						}}>
						<Box display={{xs: 'none', md: 'flex'}} gap={3}>
							{pages.map((page, i) => (
								<NavLink
									key={i}
									to={`/${getPathsFor(page.ref, lang)}`}
									style={{display: 'flex', color: 'inherit', textDecoration: 'none'}}>
									<Button
										onClick={handleCloseNavMenu}
										color='inherit'
										sx={{fontWeight: 600}}>
										{page.name[lang]}
									</Button>
								</NavLink>
							))}
						</Box>
						<IconButton
							size='large'
							aria-label='account of current user'
							aria-controls='navbar'
							aria-haspopup='true'
							onClick={handleOpenNavMenu}
							color='inherit'
							sx={{display: {xs: 'flex', md: 'none'}}}>
							<MenuRounded fontSize='large' />
						</IconButton>
						<Menu
							id='navbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: {xs: 'block', md: 'none'},
							}}>
							{pages.map((page, i) => (
								<NavLink
									key={i}
									to={`/${getPathsFor(page.ref, lang)}`}
									style={{color: 'inherit', textDecoration: 'none'}}>
									<MenuItem onClick={handleCloseNavMenu}>{page.name[lang]}</MenuItem>
								</NavLink>
							))}
						</Menu>

						<Select
							value={lang}
							displayEmpty
							inputProps={{'aria-label': 'Language selector'}}
							sx={{display: 'flex', height: '35px'}}
							onChange={({target}) => {
								const l = target.value;
								setLang(l);
								navigate(`/${l === defaultLang ? '' : l}`);
							}}
							renderValue={(selected) => (
								<Typography variant='body' fontWeight={600}>
									{selected.toUpperCase()}
								</Typography>
							)}>
							{availableLanguages().map((l, i) => (
								<MenuItem key={i} value={l}>
									{l.toUpperCase()}
								</MenuItem>
							))}
						</Select>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}

export default NavBar;
