import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {setData} from './contexts/DataContext';

(async () => {
	const data = await fetch('/data/translations').then((r) => r.json());
	setData(data);

	ReactDOM.hydrateRoot(
		document.getElementById('root'),
		<BrowserRouter>
			<App />
		</BrowserRouter>
	);
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
