import React, {useContext, useEffect, useState} from 'react';
import {Container, Typography} from '@mui/material';
import UserContext from '../../contexts/UserContext';
import Section from '../ui/Section';
import Literal from '../utils/Literal';
import {translate} from '../utils/translations';
import Picture from '../ui/Picture';

function EndesaPage() {
	const {lang} = useContext(UserContext);

	const [hasScrolled, setHasScrolled] = useState(false);
	useEffect(() => {
		if (hasScrolled) return;
		window.scroll({top: 0, left: 0, behavior: 'smooth'});
		setHasScrolled(true);
	}, [hasScrolled]);

	return (
		<Container maxWidth='xl'>
			<Section>
				<h1 style={{display: 'none'}}>{translate('endesa.title', lang)}</h1>
				<Typography component={'h2'} variant={'h2'} textAlign={'center'} mb={4}>
					<Literal target={'endesa.title'} lang={lang} />
				</Typography>
				<Picture
					src={'/public/static/imgs/ayuda-endesa.jpg'}
					style={{display: 'block', maxWidth: '100%', margin: 'auto'}}
				/>
			</Section>
		</Container>
	);
}

export default EndesaPage;
