import React, {useContext, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import parse from 'html-react-parser';
import {Container, Grid, Typography, useTheme} from '@mui/material';
import UserContext from '../../contexts/UserContext';
import Section from '../ui/Section';
import Literal from '../utils/Literal';
import {getPathsFor} from '../utils/translations';
import Picture from '../ui/Picture';
import {useDataContext} from '../../contexts/DataContext';

function AboutUs() {
	const {lang} = useContext(UserContext);
	const {palette} = useTheme();

	const {getData} = useDataContext();
	const [{content}, {team}] = getData('aboutUs', 'team');

	const path = useLocation();
	const sectionRef = useRef();

	useEffect(() => {
		if (!sectionRef?.current) return;
		if (!getPathsFor('aboutUs').includes(path.hash)) return;
		const section = sectionRef.current;
		window.scrollTo({top: section.offsetTop, left: 0, behavior: 'smooth'});
	}, [path, sectionRef]);

	return (
		<Section mt={10}>
			<Container maxWidth='lg' ref={sectionRef}>
				<Grid container direction={{xs: 'row', md: 'row-reverse'}} spacing={10}>
					<Grid item xs={12} md={6} lg={8}>
						<Typography
							component={'p'}
							variant='h3'
							mb={5}
							textAlign={{xs: 'center', md: 'left'}}>
							<Literal target={'aboutUs'} lang={lang} />
						</Typography>
						{content[lang].split('\n').map((s, i) => (
							<Typography key={i} component={'p'} variant='body' mb={4}>
								{parse(s)}
							</Typography>
						))}
					</Grid>
					{/* <Grid item xs={12} md={6} lg={4}>
						<Grid container spacing={3}>
							{team.map((member, i) => {
								return (
									<Grid key={i} item xs={4} md={6}>
										<Picture
											src={`${member.img}`}
											alt={`Face ${member.name}`}
											style={{
												position: 'relative',
												width: '100%',
												borderRadius: '50%',
												border: `5px solid ${palette.primary.light}`,
											}}
											loading='lazy'
										/>
										<Typography component={'p'} variant='body1' textAlign={'center'}>
											{member.name}
										</Typography>
										<Typography component={'p'} variant='caption' textAlign={'center'}>
											{member.rol[lang]}
										</Typography>
									</Grid>
								);
							})}
						</Grid>
					</Grid> */}
				</Grid>
			</Container>
		</Section>
	);
}

export default AboutUs;
