import React, {useContext} from 'react';
import UserContext from '../../../contexts/UserContext';
import {Button, Grid, Typography} from '@mui/material';
import Literal from '../../utils/Literal';
import parse from 'html-react-parser';
import {Link} from 'react-router-dom';
import {getPathsFor} from '../../utils/translations';
import Picture from '../../ui/Picture';

function Product({name, description, img, direction}) {
	const {lang} = useContext(UserContext);
	const basePath = getPathsFor('products', lang).replace('#', '');
	const productPath = name[lang].toLowerCase().replaceAll(' ', '-');

	return (
		<Grid container spacing={10} direction={direction || {xs: 'column', md: 'row'}}>
			<Grid item xs={12} md={6}>
				<Typography component={'h4'} variant={'title'} mb={5}>
					{name[lang]}
				</Typography>
				<Typography component={'p'} variant={'body1'} mb={3}>
					{parse(description[lang])}
				</Typography>
				<Link to={`/${basePath}/${productPath}`} style={{textDecoration: 'none'}}>
					<Button variant='outlined'>
						<Literal target='seeMore' lang={lang} />
					</Button>
				</Link>
			</Grid>
			<Grid item md={6}>
				<Picture src={img} alt={name[lang]} style={{maxWidth: '99%'}} loading='lazy' />
			</Grid>
		</Grid>
	);
}

export default Product;
