import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {
	Box,
	Container,
	Grid,
	Link,
	Paper,
	Tab,
	Tabs,
	Typography,
	useTheme,
} from '@mui/material';
import Section from '../ui/Section';
import {translate} from '../utils/translations';
import UserContext from '../../contexts/UserContext';
import ContactForm from '../ui/ContactForm';
import Literal from '../utils/Literal';
import {useDataContext} from '../../contexts/DataContext';

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return (
		<Box
			hidden={value !== index}
			id={`form-tabs-${index}`}
			aria-labelledby={`form-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{p: {xs: 0, md: 3}}}>{children}</Box>}
		</Box>
	);
}

function ContactPage() {
	const {palette} = useTheme();
	const {getData} = useDataContext();

	const [{contact}, {social}] = getData('contact', 'menu');

	const {lang} = useContext(UserContext);
	const {pathname} = useLocation();

	const [index, setIndex] = useState(pathname.endsWith('demo') ? 1 : 0);

	const handleChange = (event, value) => {
		setIndex(value);
	};

	const [hasScrolled, setHasScrolled] = useState(false);
	useEffect(() => {
		if (hasScrolled) return;
		window.scroll({top: 0, left: 0, behavior: 'smooth'});
		setHasScrolled(true);
	}, [hasScrolled]);

	return (
		<>
			<h1 style={{display: 'none'}}>{translate('contact', lang)}</h1>
			<Box
				padding={3}
				textAlign={'center'}
				sx={{
					color: palette.primary.contrastText,
					background: `linear-gradient(107.56deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%)`,
				}}>
				<Typography component={'p'} variant={'h6'} color='inherit'>
					<Literal target='ctaContact' lang={lang} />
				</Typography>
			</Box>
			<Section>
				<Container maxWidth='xl'>
					<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
						<Tabs
							value={index}
							onChange={handleChange}
							variant='fullWidth'
							textColor='primary'
							indicatorColor='primary'>
							<Tab label={translate('contact', lang)} />
							<Tab label={translate('form.requestDemo', lang)} />
						</Tabs>
					</Box>
					<TabPanel mt={5} value={index} index={0}>
						<Paper elevation={5} sx={{padding: {xs: 2, md: 5}}}>
							<ContactForm />
						</Paper>
					</TabPanel>
					<TabPanel mt={5} value={index} index={1}>
						<Paper elevation={5} sx={{padding: {xs: 2, md: 5}}}>
							<ContactForm variant={'demo'} />
						</Paper>
					</TabPanel>
				</Container>
			</Section>
			<Box
				padding={4}
				mb={-10}
				sx={{
					color: palette.primary.contrastText,
					background: `linear-gradient(107.56deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%)`,
				}}>
				<Container maxWidth='lg'>
					<Grid container spacing={5} justifyContent={'center'} mb={5}>
						<Grid item>
							<Link href={`mailto:${contact.email}`} color='inherit' underline='none'>
								{contact.email}
							</Link>
						</Grid>
						<Grid item>
							<Link href={`tel:${contact.phone}`} color='inherit' underline='none'>
								{contact.phone}
							</Link>
						</Grid>
					</Grid>
					<Grid container spacing={5} justifyContent={'center'}>
						{social.map((s, i) => (
							<Grid key={i} item>
								<Link href={s.href} target='_blank' color='inherit' underline='none'>
									{`${s.name[lang]}`}
								</Link>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>
		</>
	);
}

export default ContactPage;
