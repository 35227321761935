import * as React from 'react';
import Error from './Error';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: props?.hasError ? props.hasError : false,
			statusCode: props?.statusCode ? props.statusCode : 500,
		};
	}

	static getDerivedStateFromError(error) {
		return {hasError: true};
	}

	componentDidCatch(error, errorInfo) {
		console.log(error);
	}

	render() {
		if (this.state.hasError) return <Error statusCode={this.state.statusCode} />;
		return this.props.children;
	}
}

export default ErrorBoundary;
