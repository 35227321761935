import React, {forwardRef} from 'react';
import {
	BarChartRounded,
	CategoryRounded,
	DescriptionRounded,
	HubRounded,
	InterestsRounded,
	LanguageRounded,
	LocalGroceryStoreRounded,
	PeopleRounded,
	SettingsRounded,
} from '@mui/icons-material';

function Icon(props) {
	let IconComponentRender = CategoryRounded;

	switch (props.icon) {
		case 'language':
			IconComponentRender = LanguageRounded;
			break;
		case 'settings':
			IconComponentRender = SettingsRounded;
			break;
		case 'store':
			IconComponentRender = LocalGroceryStoreRounded;
			break;
		case 'people':
			IconComponentRender = PeopleRounded;
			break;
		case 'description':
			IconComponentRender = DescriptionRounded;
			break;
		case 'hub':
			IconComponentRender = HubRounded;
			break;
		case 'interests':
			IconComponentRender = InterestsRounded;
			break;
		case 'barChart':
			IconComponentRender = BarChartRounded;
			break;
		default:
			break;
	}

	const newProps = props;
	delete newProps.icon;
	const IconComponent = forwardRef((props, ref) => <IconComponentRender {...props} />);
	return <IconComponent {...newProps} />;
}

export default Icon;
