import React from 'react';
import {Container} from '@mui/material';
import {Outlet} from 'react-router-dom';
import Section from '../../ui/Section';
import KitDigitalPacks from './KitDigitalPacks';
import Picture from '../../ui/Picture';

function KitDigitalLayout() {
	return (
		<>
			<Outlet />
			<Container maxWidth={'xl'}>
				<Section>
					<KitDigitalPacks />
					<Picture
						src={'/public/static/imgs/kitdigital/digitalizadores.png'}
						style={{display: 'block', maxWidth: '80%', margin: '100px auto'}}
						parentStyle={{display: 'block'}}
					/>
				</Section>
			</Container>
		</>
	);
}

export default KitDigitalLayout;
