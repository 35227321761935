import React, {useContext} from 'react';
import {Box, Button, Container, Typography, useTheme} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import Literal from '../utils/Literal';
import {getPathsFor} from '../utils/translations';

function ContactCTA() {
	const {palette} = useTheme();
	const {lang} = useContext(UserContext);
	const path = useLocation();

	return (
		<Box
			display={
				getPathsFor('contact').includes(path.pathname.replace('/', '')) ? 'none' : 'block'
			}
			padding={{xs: 4, md: 10}}
			mt={5}
			mb={-10}
			sx={{
				color: palette.primary.contrastText,
				background: `linear-gradient(107.56deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%)`,
			}}>
			<Container maxWidth='xl' sx={{textAlign: 'center'}}>
				<Typography component={'h4'} variant={'h4'} mb={4} color='inherit'>
					<Literal target='ctaContact' lang={lang} />
				</Typography>
				<Link
					to={`/${getPathsFor('contact', lang)}`}
					style={{textDecoration: 'none', color: palette.secondary.contrastText}}>
					<Button variant='contained' color='secondary' size='large'>
						<Literal target='contact' lang={lang} />
					</Button>
				</Link>
			</Container>
		</Box>
	);
}

export default ContactCTA;
