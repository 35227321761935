import React, {Suspense, useContext, useEffect, useState} from 'react';
import {KeyboardArrowUpRounded} from '@mui/icons-material';
import {Slide, SpeedDial} from '@mui/material';
import {Outlet, useLocation} from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import ContactCTA from './footer/ContactCTA';
import Footer from './footer/Footer';
import Newsletter from './footer/Newsletter';
import NavBar from './nav/NavBar';
import {translate} from './utils/translations';
import ErrorBoundary from './error/ErrorBoundary';
import Cookies from './footer/Cookies';
import Loading from './ui/Loading';

function Layout({hasError, statusCode}) {
	const {lang} = useContext(UserContext);

	const {pathname, search, hash} = useLocation();
	const [lastPathName, setLastPathName] = useState('');

	const [showUpButton, setShowUpButton] = useState(
		typeof window !== 'undefined' ? window.pageYOffset > 500 : false
	);
	useEffect(() => {
		const handleChange = () => setShowUpButton(window.pageYOffset > 500);
		window.addEventListener('scroll', handleChange, true);
		return () => window.removeEventListener('scroll', handleChange);
	}, []);

	useEffect(() => {
		if (!pathname) return;
		gtag('event', 'page_view', {
			page_path: pathname,
			page_hash: hash,
			page_search: search,
		});
	}, [pathname, hash, search]);

	useEffect(() => {
		if (pathname === lastPathName) return;
		setLastPathName(pathname);
		const h1 = document.querySelector('h1');
		document.title = `Eturistic - ${h1?.innerText || translate('documentTitle', lang)}`;
		document.documentElement.lang = lang;
	}, [lang, translate, pathname, lastPathName, setLastPathName]);

	return (
		<>
			<NavBar />
			<ErrorBoundary hasError={hasError} statusCode={statusCode}>
				<Suspense fallback={<Loading />}>
					<Outlet />
				</Suspense>
			</ErrorBoundary>
			<Cookies />
			<footer>
				<ContactCTA />
				<Newsletter />
				<Footer />
			</footer>
			<Slide direction='up' in={showUpButton} mountOnEnter unmountOnExit>
				<SpeedDial
					color='primary'
					ariaLabel='Go up'
					sx={{position: 'fixed', bottom: 16, right: 16}}
					icon={<KeyboardArrowUpRounded />}
					onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}
				/>
			</Slide>
		</>
	);
}

export default Layout;
