import * as React from 'react';
import {Outlet, Route, Routes as DOMRoutes} from 'react-router-dom';
import {
	availableLanguages,
	defaultLang,
	getPathsFor,
	translate,
} from './utils/translations';
import Clients from './main/Clients';
import CTA from './main/CTA';
import Header from './main/Header';
import Products from './main/products/Products';
import Error from './error/Error';
import ContactPage from './pages/ContactPage';
import AboutUs from './main/AboutUs';
import ProductPage from './pages/ProductPage';
import KitDigital from './pages/kitdigital/KitDigital';
import KitDigitalDetail from './pages/kitdigital/KitDigitalDetail';
import KitDigitalLayout from './pages/kitdigital/KitDigitalLayout';
import LegalPolicy from './pages/LegalPolicy';
import Layout from './Layout';
import EndesaPage from './pages/EndesaPage';

function Routes(props) {
	const [kitdigital, products] = props.data;

	const lang = props?.lang;
	const statusCode = props?.statusCode;
	const error = statusCode && statusCode !== 200;

	const innerRoutes = (l) => {
		return (
			<Route key={l} path={`${l === defaultLang ? '' : l}`}>
				<Route index element={<Main lang={l} />} />
				<Route path={getPathsFor('contact', l, false)}>
					<Route index element={<ContactPage />} />
					<Route path={'demo'} element={<ContactPage />} />
				</Route>
				<Route path={getPathsFor('legalPolicy', l, false)} element={<LegalPolicy />} />
				<Route path={getPathsFor('endesa', l, false)} element={<EndesaPage />} />
				<Route path={getPathsFor('kitDigital', l, false)} element={<KitDigitalLayout />}>
					<Route index element={<KitDigital />} />
					{kitdigital.packs.packs.map((pack, i) => (
						<Route key={i} path={pack.ref} element={<KitDigitalDetail pack={pack} />} />
					))}
				</Route>
				<Route
					path={getPathsFor('products', l, false).replace('#', '')}
					element={<Outlet />}>
					<Route index element={<ProductPage product={products.products[0]} />} />
					{products.products.map((product, i) => (
						<Route
							key={i}
							path={product.name[l].toLowerCase().replaceAll(' ', '-')}
							element={<ProductPage product={product} />}
						/>
					))}
				</Route>
			</Route>
		);
	};

	return (
		<DOMRoutes>
			<Route path='/' element={<Layout hasError={error} statusCode={statusCode} />}>
				{lang ? innerRoutes(lang) : availableLanguages().map((l) => innerRoutes(l))}
				<Route path='*' element={<Error statusCode={statusCode} />} />
			</Route>
		</DOMRoutes>
	);
}

const Main = ({lang}) => (
	<>
		<Header />
		<h1 style={{display: 'none'}}>{translate('documentTitle', lang)}</h1>
		<main id='main'>
			<CTA />
			<Products />
			<Clients />
			<AboutUs />
		</main>
	</>
);

export default Routes;
