import React, {useEffect, useRef, useState} from 'react';

function Picture({src, alt, width, height, loading, sources, style, parentStyle}) {
	const srcSplit = src.split('.');
	const ext = srcSplit[srcSplit.length - 1];
	const correctExtension = ext === 'png' || ext === 'jpg' || ext === 'jpeg';
	const webpVersion = src.replace(ext, 'webp');

	const imgRef = useRef();

	const [styleWidth, setWidth] = useState(style?.width || width);
	const [styleHeight, setHeight] = useState(style?.height || height);
	const [dimensions, setDimensions] = useState({width: styleWidth, height: styleHeight});

	useEffect(() => {
		if (dimensions.width && dimensions.height) return;
		if (!imgRef.current) return;
		const img = imgRef.current;
		img.onload = () => {
			const newDimensions = {width: width || img.width, height: height || img.height};
			if (!styleWidth) setWidth('auto');
			if (!styleHeight) setHeight('auto');
			setDimensions(newDimensions);
		};
	}, [imgRef, width, height, styleWidth, styleHeight]);

	return (
		<picture style={parentStyle}>
			{!!sources
				? sources.map((source) => <source srcSet={source.src} media={source.media} />)
				: (() => {
						if (!correctExtension) return <></>;
						return <source srcSet={src} media={`image/${ext}`} />;
				  })()}
			<img
				ref={imgRef}
				src={correctExtension ? webpVersion : src}
				alt={alt}
				width={width || dimensions.width}
				height={height || dimensions.height}
				style={{
					...(style || {}),
					...(styleWidth ? {width: styleWidth} : {}),
					...(styleHeight ? {height: styleHeight} : {}),
				}}
				loading={loading}
			/>
		</picture>
	);
}

export default Picture;
