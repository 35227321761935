import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	Typography,
	useTheme,
} from '@mui/material';
import {getPathsFor} from '../../utils/translations';
import UserContext from '../../../contexts/UserContext';
import Literal from '../../utils/Literal';
import Icon from '../../ui/Icon';
import {useDataContext} from '../../../contexts/DataContext';

function KitDigitalPacks() {
	const {palette} = useTheme();
	const {lang} = useContext(UserContext);

	const {getData} = useDataContext();
	const [{packs}] = getData('kitdigital');

	const linearGradient = `linear-gradient(107.56deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%)`;

	return (
		<>
			<Typography component={'h3'} variant={'h4'} mb={5}>
				{packs.title}
			</Typography>
			<Grid container spacing={3} alignItems={'end'}>
				{packs.packs.map((pack, i) => (
					<Grid key={i} item position={'relative'} xs={12} md={6} lg={4} mt={'50px'}>
						<Card sx={{width: '100%', padding: 2}}>
							<Box
								position='absolute'
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									width: '100px',
									height: '100px',
									left: '50%',
									borderRadius: '50%',
									transform: 'translate(-50%, -50%)',
									fontSize: 50,
									color: palette.primary.contrastText,
									background: linearGradient,
								}}>
								<Icon icon={pack.icon || ''} color='inherit' fontSize='inherit' />
							</Box>
							<CardContent>
								<Link
									to={`/kit-digital/${pack.ref}`}
									style={{textDecoration: 'none', color: 'inherit'}}>
									<Typography gutterBottom variant='h6' textAlign={'center'} mt={'50px'}>
										{pack.title}
									</Typography>
									<Typography
										gutterBottom
										variant='subtitle2'
										color={'GrayText'}
										textAlign={'center'}
										mb={2}>
										{pack.category}
									</Typography>
									<Typography variant='body1' color='text.secondary'>
										{pack.description}
									</Typography>
								</Link>
							</CardContent>
							<CardActions sx={{display: 'flex', justifyContent: 'center', gap: 10}}>
								<Link
									to={`/kit-digital/${pack.ref}`}
									style={{textDecoration: 'none', color: 'inherit'}}>
									<Button>
										<Literal target={'seeMore'} lang={lang} />
									</Button>
								</Link>
								<Link
									to={`/${getPathsFor('contact', lang)}`}
									style={{textDecoration: 'none', color: 'inherit'}}>
									<Button variant='contained'>
										<Literal target={'contact'} lang={lang} />
									</Button>
								</Link>
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>
		</>
	);
}

export default KitDigitalPacks;
