import React from 'react';
import {Divider, Grid, Paper, Typography} from '@mui/material';
import Section from '../../ui/Section';
import {useDataContext} from '../../../contexts/DataContext';

function KitDigitalSegments() {
	const {getData} = useDataContext();
	const [{segments}] = getData('kitdigital');

	return (
		<>
			<Typography component={'h3'} variant={'h4'} my={3}>
				{segments.title}
			</Typography>
			<Section>
				<Grid
					container
					direction={{xs: 'column', md: 'row'}}
					spacing={10}
					alignItems={'stretch'}>
					{segments.segments.map((s, i) => (
						<Grid key={i} item xs={4}>
							<Paper elevation={5} sx={{padding: 5, height: '100%'}}>
								<Typography component={'h4'} variant={'h6'} textAlign='center'>
									{s.title}
								</Typography>
								<Divider sx={{marginTop: 2, marginBottom: 2}} />
								<Typography component={'p'} variant={'body'} textAlign='center'>
									{s.description}
								</Typography>
								<Divider sx={{marginTop: 2, marginBottom: 2}} />
								<Typography component={'p'} variant={'body'} textAlign='center'>
									{'Importe de '}
									<Typography
										component={'span'}
										variant={'body'}
										color='primary'
										fontWeight={600}>
										{s.import}
									</Typography>
									{'€'}
								</Typography>
							</Paper>
						</Grid>
					))}
				</Grid>
			</Section>
		</>
	);
}

export default KitDigitalSegments;
