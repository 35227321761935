import React, {useContext} from 'react';
import UserContext from '../../contexts/UserContext';
import Literal from '../utils/Literal';
import {
	Box,
	Button,
	Container,
	Grid,
	Paper,
	Typography,
	styled,
	useTheme,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {getPathsFor} from '../utils/translations';
import {NavLink} from 'react-router-dom';
import ContactForm from '../ui/ContactForm';

function Header() {
	const {lang} = useContext(UserContext);
	const theme = useTheme();

	const HeaderElement = styled('header')({
		position: 'relative',
		height: 'calc(100vh - 100px)',
		background: `linear-gradient(107.56deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 100%)`,
		backgroundSize: 'cover',
	});

	return (
		<>
			<HeaderElement>
				<Container
					maxWidth='lg'
					sx={{display: 'flex', height: {xs: 'calc(100% - 100px)', md: 'auto'}}}>
					<Grid
						container
						direction={{xs: 'column', md: 'row'}}
						m={'auto'}
						alignItems={'center'}
						justifyContent={'space-between'}
						spacing={4}>
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'start',
								gap: {xs: 2, md: 5},
								paddingLeft: {xs: '0px !important'},
								textAlign: {xs: 'center', md: 'left'},
							}}>
							<Typography
								color='white'
								component='h2'
								variant={'h2'}
								fontWeight={600}
								fontSize={{xs: '2.75rem', md: '3.75rem'}}>
								<Literal target='title' lang={lang} />
							</Typography>
							<Typography
								color='white'
								component='h3'
								variant='h4'
								fontSize={{xs: '1.5rem', md: '2.125rem'}}>
								<Literal target='subtitle' lang={lang} />
							</Typography>
							<Button
								size='large'
								variant='contained'
								color='secondary'
								sx={{display: {xs: 'block', md: 'none'}, margin: 'auto'}}>
								<NavLink
									to={`/${getPathsFor('contact', lang)}/demo`}
									style={{color: 'inherit', textDecoration: 'none'}}>
									<Literal target='form.requestDemo' lang={lang} />
								</NavLink>
							</Button>
						</Grid>
						<Grid item xs={12} md={6} display={{xs: 'none', md: 'block'}} sx={{zIndex: 100}}>
							<Paper elevation={12} sx={{display: {xs: 'none', md: 'block'}, padding: 5}}>
								<ContactForm variant={'demo'} />
							</Paper>
						</Grid>
					</Grid>
				</Container>
				<Button
					variant='contained'
					color='inherit'
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						bottom: 0,
						width: {xs: '60px', md: '90px'},
						height: {xs: '60px', md: '90px'},
						paddingTop: '10px',
						margin: '0 auto',
						marginBottom: {xs: '15px', md: 'clamp(10px, 5vh, 40px)'},
						borderRadius: '50%',
						textAlign: 'center',
						color: '#1d1d1d',
						background: '#fff',
						cursor: 'pointer',
						transition: 'all 0.3s ease-in-out',
						boxShadow: 'none',
						zIndex: 10,
					}}
					onClick={() =>
						document.querySelector('main').scrollIntoView({behavior: 'smooth'})
					}>
					<KeyboardArrowDownIcon fontSize='large' />
				</Button>
				<Box
					mb={8}
					sx={{
						'&::after': {
							content: '""',
							display: 'block',
							position: 'absolute',
							right: 0,
							bottom: '-1px',
							width: '50%',
							height: 'clamp(30px, 5vmax, 90px)',
							backgroundColor: '#fff',
							zIndex: '1',
						},
						'&::before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							left: 0,
							bottom: 0,
							width: '100%',
							height: 'clamp(50px, 5vmax, 100px)',
							transform: 'skewY(-3deg)',
							backgroundColor: '#fff',
							zIndex: '1',
						},
					}}></Box>
			</HeaderElement>
		</>
	);
}

export default Header;
