import React, {useContext, useState} from 'react';
import {
	Button,
	Checkbox,
	Container,
	FormControlLabel,
	FormGroup,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import UserContext from '../../contexts/UserContext';
import {translate} from '../utils/translations';
import Literal from '../utils/Literal';

const getCookies = (name) => {
	if (typeof document === 'undefined') return;
	if (!name) {
		return document.cookie.split(';').map((c) => {
			const cookie = {};
			cookie[`${c.split('=')[1]}`] = c.split('=')[1];
			return cookie;
		});
	}
	return document.cookie
		.split('; ')
		.find((c) => c.startsWith(`${name}=`))
		?.split('=')[1];
};

function setCookie(name, value, days = 30) {
	const data = new Date();
	data.setTime(data.getTime() + days * 24 * 60 * 60 * 1000);
	document.cookie = `${name}=${value};expires=${data.toUTCString()};path=/`;
}

function Cookies() {
	const {lang, cookiesAccepted} = useContext(UserContext);
	const [accepted, setAccepted] = useState(!!getCookies('accepted') || cookiesAccepted);

	const handleSubmit = (e) => {
		e.preventDefault();
		setAccepted(true);
		setCookie('accepted', true);
		const formData = new FormData(e.target);
		const analytics = formData.get('analytics') && formData.get('analytics') === 'on';
		const ad = formData.get('ad') && formData.get('ad') === 'on';
		gtag('consent', 'update', {
			ad_storage: ad ? 'granted' : 'denied',
			analytics_storage: analytics ? 'granted' : 'denied',
		});
	};

	return accepted ? (
		<></>
	) : (
		<Container
			maxWidth='lg'
			sx={{
				position: 'fixed',
				left: '50%',
				bottom: 20,
				transform: 'translateX(-50%)',
				zIndex: 100,
			}}>
			<Paper sx={{width: '100%', padding: 3, margin: 'auto'}}>
				<form onSubmit={handleSubmit}>
					<Typography component={'p'} variant={'body1'} mb={2}>
						<Literal target='cookies.text' lang={lang} />
					</Typography>
					<FormGroup sx={{display: 'flex', flexDirection: 'row'}}>
						<FormControlLabel
							control={<Checkbox name='analytics' defaultChecked />}
							label={translate('cookies.analytics', lang)}
						/>
						<FormControlLabel
							control={<Checkbox name='ad' defaultChecked />}
							label={translate('cookies.ad', lang)}
						/>
						<FormControlLabel
							control={<Checkbox defaultChecked disabled />}
							label={translate('cookies.functional', lang)}
						/>
					</FormGroup>
					<Stack
						direction={{xs: 'column-reverse', sm: 'row'}}
						spacing={{xs: 1, sm: 2, md: 4}}
						justifyContent={'right'}
						mt={{xs: 2, sm: 0}}>
						<Button variant='text' onClick={() => setAccepted(true)}>
							<Literal target='cookies.reject' lang={lang} />
						</Button>
						<Button variant='contained' type='submit'>
							<Literal target='cookies.accept' lang={lang} />
						</Button>
					</Stack>
				</form>
			</Paper>
		</Container>
	);
}

export default Cookies;
