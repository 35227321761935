import React from 'react';
import {Box} from '@mui/material';

function Section(props) {
	const {sx} = props;

	return (
		<Box
			{...props}
			sx={{
				paddingTop: 5,
				paddingBottom: 5,
				...sx,
			}}
		/>
	);
}

export default Section;
