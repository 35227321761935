import React from 'react';
import {
	Box,
	Container,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	useTheme,
} from '@mui/material';
import {LabelImportantRounded} from '@mui/icons-material';
import KitDigitalSegments from './KitDigitalSegments';
import parse from 'html-react-parser';
import Picture from '../../ui/Picture';
import {useDataContext} from '../../../contexts/DataContext';

function KitDigital() {
	const {palette} = useTheme();
	const {getData} = useDataContext();
	const [{title, subtitle, description, whatIsIt, benefactors, categories}] =
		getData('kitdigital');

	const linearGradient = `linear-gradient(107.56deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%)`;

	return (
		<>
			<h1 style={{display: 'none'}}>{title}</h1>
			<Box
				padding={3}
				mb={4}
				sx={{color: palette.primary.contrastText, background: linearGradient}}>
				<Typography component={'p'} variant={'h6'} color='inherit' textAlign='center'>
					{subtitle}
				</Typography>
			</Box>
			<Container maxWidth='xl'>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<Typography component={'h2'} variant={'h2'} textAlign='center' mb={4}>
							{title}
						</Typography>
						<Typography component={'p'} variant={'body'}>
							{description}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography component={'h3'} variant={'h4'} textAlign='center' mb={4}>
							{whatIsIt.title}
						</Typography>
						<Typography component={'p'} variant={'body'}>
							{whatIsIt.description}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<KitDigitalSegments />
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={4}>
							<Grid item xs={12} md={6}>
								<Typography component={'h3'} variant={'h4'} mb={5}>
									{benefactors.title}
								</Typography>
								<List>
									{benefactors.rules.map((rule, i) => (
										<ListItem key={i}>
											<ListItemIcon>
												<LabelImportantRounded color='primary' />
											</ListItemIcon>
											<ListItemText>{rule.title}</ListItemText>
										</ListItem>
									))}
								</List>
							</Grid>
							<Grid item xs={12} md={6}>
								<Picture width={'100%'} src={benefactors.img} alt='gift' loading='lazy' />
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						spacing={4}
						direction={{xs: 'row', md: 'row-reverse'}}>
						<Grid item xs={12} md={6}>
							<Typography component={'h3'} variant={'h4'} mb={5}>
								{categories.title}
							</Typography>
							<List>
								{categories.categories.map((category, i) => (
									<ListItem key={i}>
										<ListItemIcon>
											<LabelImportantRounded color='primary' />
										</ListItemIcon>
										<ListItemText>{category.description}</ListItemText>
									</ListItem>
								))}
							</List>
							<Typography
								component={'div'}
								variant={'body'}
								sx={{'& a': {color: palette.primary.main}}}>
								{parse(categories.footer)}
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Picture
								width={'100%'}
								src={categories.img}
								alt='categories'
								loading='lazy'
							/>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default KitDigital;
