import React, {createContext} from 'react';
import {defaultLang} from '../components/utils/translations';

const UserContext = createContext({
	lang: defaultLang,
	setLang: (lang) => {},
	page: 'main',
	setPage: (page) => {},
});

export default UserContext;
